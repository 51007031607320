var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"z-index":10,"title":_vm.title,"visible":_vm.visible,"width":"60%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.visible=$event},"close":_vm.closeModal}},[_c('ax-form',{ref:"form",attrs:{"formBuilder":_vm.formBuilder},on:{"change":_vm.change}},[_c('div',{staticClass:"divider",attrs:{"slot":"baseInfo"},slot:"baseInfo"},[_vm._v(" 基本信息")]),_c('div',{attrs:{"slot":"fileIds"},slot:"fileIds"},[_c('imageUpload',{ref:"img1",attrs:{"businessId":_vm.businessId}})],1),_c('div',{attrs:{"slot":"nativePlace"},slot:"nativePlace"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options1',
          { rules: [{ required: false, message: '请选择籍贯' }] },
        ]),expression:"[\n          'options1',\n          { rules: [{ required: false, message: '请选择籍贯' }] },\n        ]"}],attrs:{"getPopupContainer":(triggerNode) => triggerNode.parentNode,"options":_vm.options1,"placeholder":"请选择籍贯","allowClear":"","show-search":{ filter: _vm.filter }},on:{"change":_vm.onChange1}})],1),_c('div',{staticClass:"divider",attrs:{"slot":"contactInfo"},slot:"contactInfo"},[_vm._v(" 联系信息")]),_c('div',{staticClass:"divider",attrs:{"slot":"domicileInfo"},slot:"domicileInfo"},[_vm._v(" 基本信息")]),_c('div',{attrs:{"slot":"residencePlace"},slot:"residencePlace"},[_c('a-cascader',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'options3',
          { rules: [{ required: false, message: '请选择' }] },
        ]),expression:"[\n          'options3',\n          { rules: [{ required: false, message: '请选择' }] },\n        ]"}],attrs:{"options":_vm.options3,"placeholder":"请选择户籍地","change-on-select":"","show-search":{ filter1: _vm.filter1 },"fieldNames":{ label: 'label', value: 'label', children: 'children' },"allowClear":"","getPopupContainer":(triggerNode) => triggerNode.parentNode,"z-index":666},on:{"change":_vm.onChange3}})],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticStyle:{"background-color":"#556bff"},attrs:{"type":"primary","loading":_vm.saveType},on:{"click":_vm.onSubmit}},[_vm._v("保 存")]),_c('el-button',{on:{"click":_vm.onCancel}},[_vm._v("取 消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }